<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <list></list>
    </v-col>
  </v-row>
</template>

<script>
import List from "@/components/ToDoList/Widgets/List";

// eslint-disable-next-line object-curly-newline
// import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

export default {
  components: {
    List,
  },
  setup() {

  },
}
</script>
