<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Prioritised TO-DOs</span>
      <v-spacer></v-spacer>

<!--      <v-btn-->
<!--        icon-->
<!--        small-->
<!--        class="me-n3 mt-n2"-->
<!--      >-->
<!--        <v-icon>-->
<!--          {{ icons.mdiDotsVertical }}-->
<!--        </v-icon>-->
<!--      </v-btn>-->
    </v-card-title>

    <v-card-text class="pl-0 pr-0 pb-1">
      <div class="d-flex align-center">
        <p class="w-full pa-4 text-center" v-if="!processedTasks || !processedTasks.length">
          Seems like there is no <span class="warning--text">prioritised</span> nor <span class="error--text">urgent</span> tasks, hooray!
        </p>

        <v-list class="w-full pb-2" v-if="processedTasks && processedTasks.length">
          <v-list-item dense v-for="todoTask in processedTasks" :v-key="todoTask.id">
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col class="pa-3 col-3 col-md-1" order="3" order-md="3"
                       v-if="todoTask.assigned_to && members[todoTask.assigned_to]">
                  <v-avatar
                    class="task-assigned-avatar"
                    v-bind="{color: todoTask.assigned_to ? 'primary' : ''}"
                    size="24"
                  >
                    <span>
                      <span
                        v-if="!members[todoTask.assigned_to].img"
                        class="white--text"
                      >
                        {{ getShortName(members[todoTask.assigned_to]) }}
                      </span>
                      <v-img
                        v-if="members[todoTask.assigned_to].img"
                        :src="members[todoTask.assigned_to].img"
                      ></v-img>
                    </span>
                  </v-avatar>
                </v-col>
                <v-col class="todo-text pa-3 pt-4 col-9 col-md-7" order="1" order-md="1">
                  {{ todoTask.text }}
                </v-col>
                <v-col class="col-6 col-md-1" order="4" order-md="4">
                  <v-icon
                    v-if="todoTask.date"
                    size="18"
                    class="mt-0"
                    :class="getDateClass(todoTask.date)"
                  >
                    {{ icons.mdiCalendar }}
                  </v-icon>
                </v-col>
                <v-col class="pa-3 col-3 col-md-3" order="2" order-md="2">
                  <v-chip
                    small
                    class="font-weight-medium"
                    :class="getPriorityBadgeClass(todoTask)"
                    outlined
                  >
                    {{ todoTask.finished ? 'Done' : getPriorities()[todoTask.priority].label }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card-text>

    <v-card-actions class="mr-4 d-block w-full text-right text-sm">
      <router-link :to="{ name:'todo-lists' }">
        View All
      </router-link>
    </v-card-actions>

<!--    <v-card-text>-->
<!--      <div-->
<!--        v-for="(earning,index) in totalEarning"-->
<!--        :key="earning.avatar"-->
<!--        :class="`d-flex align-start ${index >0 ? 'mt-8':''}`"-->
<!--      >-->
<!--        <v-avatar-->
<!--          rounded-->
<!--          size="38"-->
<!--          color="#5e56690a"-->
<!--          class="me-4"-->
<!--        >-->
<!--          <v-img-->
<!--            contain-->
<!--            :src="earning.avatar"-->
<!--            height="20"-->
<!--          ></v-img>-->
<!--        </v-avatar>-->

<!--        <div class="d-flex align-center flex-grow-1 flex-wrap">-->
<!--          <div>-->
<!--            <h4 class="font-weight-medium">-->
<!--              {{ earning.title }}-->
<!--            </h4>-->
<!--            <span class="text-xs text-no-wrap">{{ earning.subtitle }}</span>-->
<!--          </div>-->

<!--          <v-spacer></v-spacer>-->

<!--          <div class="ms-1">-->
<!--            <p class="text&#45;&#45;primary font-weight-medium mb-1">-->
<!--              {{ earning.earning }}-->
<!--            </p>-->
<!--            <v-progress-linear-->
<!--              :value="earning.progress"-->
<!--              :color="earning.color"-->
<!--            ></v-progress-linear>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </v-card-text>-->
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiMenuUp, mdiCalendar } from '@mdi/js'
import {mapState} from "vuex";

import TodoListProcessor from '@/helpers/todo-list-processor';
import {getShortName} from '@/helpers/helpers';

export default {
  data() {
    return {
      processedTasks: []
    }
  },
  watch: {
    tasks() {
      this.reprocessTasks()
    },
    async currentProject() {
      await this.$store.dispatch('fetchTodos')
      await this.$store.dispatch('getUsersList')
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchTodos')
    await this.$store.dispatch('getUsersList')
  },
  setup() {
    return {
      icons: {
        mdiCalendar,
        mdiDotsVertical,
        mdiMenuUp
      },
    }
  },
  computed: {
    ...mapState({
      members: 'currentProjectUsers',
      tasks: 'todoTasks',
      lists: 'todoLists',
      currentProject: 'currentProject',
    }),
  },
  methods: {
    reprocessTasks() {
      const todoListProcessor = new TodoListProcessor()
      this.processedTasks = todoListProcessor.process(
        this.tasks,
        {
          exclude_done: true,
          priority: {
            operator: 'nin',
            val: [0, 1]
          }
        },
        {
          field: 'priority'
        }
      )
    },
    getShortName(user) {
      return getShortName(user)
    },
    getPriorities() {
      return {
        0: {
          id: 0,
          label: 'Hold',
          class: 'info',
        },
        1: {
          id: 1,
          label: 'Normal',
          class: 'success',
        },
        2: {
          id: 2,
          label: 'Prioritized',
          class: 'warning',
        },
        3: {
          id: 3,
          label: 'Urgent',
          class: 'error',
        },
        4: {
          id: 4,
          label: 'In Work',
          class: 'primary',
        },
      }
    },
    getDateClass(date) {
      // @TODO: process timezone!!!
      const dateObj = new Date(date)
      const curDateObj = new Date()

      const diffTime = dateObj - curDateObj
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      let textClass = ''
      switch (true) {
        case !date:
          break
        case diffDays <= 0:
          textClass = 'error--text'
          break
        case diffDays > 0 && diffDays <= 3:
          textClass = 'warning--text'
          break
        case diffDays > 0:
          textClass = 'success--text'
          break
      }

      return textClass
    },

    getPriorityBadgeClass(element) {
      if (!element.finished && element.text) {
        const priorities = this.getPriorities()

        return [
          priorities[element.priority].class,
          `${priorities[element.priority].class}--text`,
        ]
      }

      return []
    },
  }
}
</script>

<style scoped>
  .todo-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
