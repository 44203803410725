export default class TodoListProcessor {
  process(tasks, filter, sort) {
    let processed = tasks.slice()

    if (filter) {
      if (filter.exclude_done) {
        processed = this.#filter(
          processed,
          'finished',
          '0'
        )
      }

      if (filter.priority) {
        processed = this.#filter(
          processed,
          'priority',
          filter.priority.val,
          filter.priority.operator
        )
      }

      if (filter.assigned_to) {
        processed = this.#filter(
          processed,
          'assigned_to',
          filter.assigned_to.val,
          filter.assigned_to.operator
        )
      }

      if (filter.date) {
        processed.filter(t => {
          const dateObj = new Date(t.date)
          const curDateObj = new Date()

          const diffTime = dateObj - curDateObj
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

          if (t.date) {
            if (diffDays <= 0) {
              if (filter.date === -1) {
                return true
              }
            } else if (diffDays > 0 && diffDays <= 3) {
              if (filter.date === 0) {
                return true
              }
            } else if (diffDays > 0) {
              if (filter.date === 1) {
                return true
              }
            }
          } else {
            if (filter.date === false) {
              return true;
            }
          }
        })
      }
    }

    if (sort && sort.field) {
      processed = processed.sort((a, b) => ((b[sort.field] - a[sort.field]) * !sort.desc))
    }

    return processed
  }

  #filter(items, field, val, operator = 'eq') {
    switch (operator) {
      case 'eq':
        return items.filter(it => val === it[field])
      case 'in':
        return items.filter(it => val.indexOf(it[field]) !== -1)
      case 'nin':
        return items.filter(it => val.indexOf(it[field]) === -1)
    }
  }
}
